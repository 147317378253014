import React from 'react'
import * as S from './styled'
import Hyphenated from 'react-hyphen';

import PropTypes from 'prop-types'

const PostItem = ({ slug, category, title, date }) => (
    <S.PostItemWrapper>
        <S.PostItemTitle 
            style={{ margin: "0.2rem 0 1.5rem" }} 
            href={slug}
        >
            <Hyphenated>
                {title}
            </Hyphenated>
        </S.PostItemTitle>
        <S.LinkAbout style={{ marginLeft: 0, width: "fit-content" }} to={slug}>Leia mais</S.LinkAbout>
        <S.PostItemTags>{category}</S.PostItemTags>
    </S.PostItemWrapper>
)

PostItem.propTypes = {
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.string,
    date: PropTypes.string,
}

export default PostItem