import React from 'react'
import Layout from "../components/Layout/"
import Seo from "../components/seo"
import PostItem from '../components/PostItem'

import { graphql } from 'gatsby'
import PostListWrapper from '../components/PostListWrapper'
import TextStroke from '../components/TextStroke'
import Pagination from '../components/Pagination'

const style = {
    wrapper: {
       width: "100%",
       flex: 1,
       display: "flex",
       flexDirection: "column",
       marginTop: "5rem",
       maxWidth: "1500px",
       margin: "50px auto",
    },
    textStroke: {
       fontFamily: "Bungee",
       fontSize: "6rem",
       width: "fit-content",
       marginBottom: "3.5rem",
    }
}

export const query = graphql`
        query Posts($skip: Int!, $limit: Int!) {
            allMarkdownRemark(
                sort: {fields: frontmatter___date, order: DESC}
                limit: $limit
                skip: $skip
            ) {
            nodes {
                fields {
                    slug
                }
                frontmatter {
                    author
                    category
                    title
                    date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
                }
                timeToRead
            }
            }
        }
    `

const BlogList = (props) => {
    const posts = props.data.allMarkdownRemark.nodes
    const { currentPage, numPages } = props.pageContext

    const prevPage = currentPage - 1 === 1 ? '/blog' : `/blog/${currentPage - 1}`
    const nextPage = `/blog/${currentPage + 1}`

    return (
        <Layout paddingRight="5rem" page="blog">
            <Seo title="blog"/>
            <div style={{ ...style.wrapper }}>
                <TextStroke style={{ ...style.textStroke}}>
                    Blog
                </TextStroke>
                <PostListWrapper>
                {posts && posts.map(({ frontmatter : { date, title, category }, fields: { slug }, timeToRead }) => {
                    return (
                        <PostItem
                            key={slug}
                            date={date}
                            slug={slug}
                            title={title}
                            category={category}
                            timeToRead={timeToRead}
                        />
                    )
                })}
                </PostListWrapper>
                <Pagination
                    isFirstPage={currentPage === 1}
                    isLastPage={currentPage === numPages}
                    currentPage={currentPage}
                    numPages={numPages}
                    prevPage={prevPage}
                    nextPage={nextPage}
                />
            </div>
        </Layout>
    )
}

export default BlogList
