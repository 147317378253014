import styled from 'styled-components'

export const TextStroke = styled.span`
    -webkit-text-stroke: 3px rgb(240, 162, 43);
    color: transparent;
    border: none;
    outline: none;
    position: relative;
    font-family: "Bungee";
    font-size: 5vw;

    &::after {
        border: 0 solid transparent;
        transition: all 0.5s;
        content: '';
        position: absolute;
        width: 0;
        border-bottom: 3px solid rgb(240,162,43);
        left: 0;
        bottom: 0;
    }

    &:hover::after {
        width: 100%;
    }

    @media(max-width: 1000px) {
        font-size: 10vw; 
        line-height: 4.5rem ;
    }

    @media(max-width: 1000px) {
        font-size: 10vw; 
        line-height: 4.5rem ;
    }

    @media(max-width: 600px) {
        font-size: 12vw;
        line-height: 3.5rem;
    }

    @media(max-width: 400px) {
        font-size: 13vw;
        line-height: 3rem;
        margin: 0;
    }
`