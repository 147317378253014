import React from 'react'
import * as S from './styled'
import propTypes from 'prop-types'

const Pagination = ({ isFirstPage, isLastPage, currentPage, numPages, prevPage, nextPage }) => {
    return (
        <S.PaginationWrapper>
            <S.PaginationLink 
                to={prevPage} 
                style={{ 
                    pointerEvents: isFirstPage ? "none" : "initial",
                    color: isFirstPage ? "#c0c9d0" : "inherit" 
                }}
            > 
                Página anterior
            </S.PaginationLink>
    
            <p>{currentPage} de {numPages}</p>
    
            <S.PaginationLink 
                to={nextPage} 
                style={{ 
                    pointerEvents: isLastPage ? "none" : "initial",
                    color: isLastPage ? "#c0c9d0" : "inherit" 
                }}
            >
                Próxima página 
            </S.PaginationLink>
        </S.PaginationWrapper>
    )
}

Pagination.propTypes = {
    isFirstPage: propTypes.bool.isRequired,
    isLastPage: propTypes.bool.isRequired,
    currentPage: propTypes.number.isRequired,
    numPages: propTypes.number.isRequired,
    prevPage: propTypes.string,
    nextPage: propTypes.string
}

export default Pagination