import styled from 'styled-components'
import { LinkAbout as StyledLinkAbout } from '../LinksWrapper/styled'

export const PostItemWrapper = styled.section`
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    justify-content: space-between;
`

export const LinkAbout = StyledLinkAbout

export const PostItemTags = styled.div`
    align-items: center;
    border-radius: 50%;
    color: #515151;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    font-family: "Bebas Neue";
    margin-top: 15px;
`

export const PostItemTitle = styled.a`
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0.2rem 0 0.5rem;

  font-family: "Bungee";
  font-size: 1.3rem;
  line-height: 2rem;
  text-decoration: none;
  color: #333;

  & :visited {
    color: inherit;
  }

  & :hover {
    color: inherit;
  }
`