import styled from 'styled-components'

export const PostListWrapper = styled.section`
    display: grid;
    width: 100%;

    margin: 0 auto;

    grid-gap: 5rem;    
    grid-template-columns: repeat(auto-fill, minmax(calc(10% + 17.5rem), 1fr));
`