import styled from 'styled-components'
import { Link } from 'gatsby'

export const PaginationWrapper = styled.section`
  align-items: center;
  display: flex;
  padding: 5rem 3rem 1.5rem 3rem;
  justify-content: center;
  z-index: 1;
  margin-top: auto;
  font-family: Bebas Neue;

  p {
    margin: 15px;
    color: inherit;
  }

`
export const PaginationLink = styled(Link)`
  color: #333;
  text-decoration: none;
  transition: color 0.5s;

  &:hover {
    color: #1fa1f2;
  }
`